// external libraries
import request from "superagent";
import store from "@/store/index";

const external3rdPartyURL = process.env.APP_CUSTOMER.getExternal3rdPartyURL;

export const defaultMetadata = process.env.APP_CUSTOMER.defaultMetadata;

export const defaultDisclaimer = process.env.APP_CUSTOMER.defaultDisclaimer;

// ======================
// Get a valid token ====
// ======================
const getExternalJWTToken = uuid => {
  return new Promise((resolve, reject) => {
    request
      .get("/api/get_token/" + uuid + "/")
      .then(resp => {
        if (process.env.NODE_ENV == "development") {
          console.log(resp.status);
          console.log(resp);
        }
        store.commit("raiseSnackbar", {
          text: "Connected",
          color: "primary",
          timeout: 2000
        });
        resolve(resp);
      })
      .catch(error => {
        store.commit("raiseSnackbar", {
          text: "Connection error, please try again later.",
          color: "error",
          timeout: -1
        });
        reject(error);
      });
  });
};

// ====================
// Get dicom study ====
// ====================
const get3rdPartyDicomStudyInfo = (uuid, jwt) => {
  return new Promise((resolve, reject) => {
    request
      .get(external3rdPartyURL + "dicom-folder/" + uuid)
      .set("token", jwt)
      .responseType("json")
      .then(resp => {
        if (process.env.NODE_ENV == "development") {
          console.log(resp.status);
        }
        resolve(resp);
      })
      .catch(error => reject(error));
  });
};

const download3rdPartyDicomFiles = (
  blobIds,
  nIds,
  jwt,
  dicomFiles,
  resolveFn
) => {
  let file_uuid = blobIds.pop();
  if (file_uuid) {
    getDicomSeries(file_uuid, jwt).then(resp => {
      let log =
        "Downloading file: " +
        (parseInt(dicomFiles.length) + 1) +
        " of " +
        nIds;
      store.commit("raiseSnackbar", {
        text: log,
        color: "primary",
        timeout: -1
      });

      dicomFiles.push(new File([resp.body], file_uuid));
      download3rdPartyDicomFiles(blobIds, nIds, jwt, dicomFiles, resolveFn);
    });
  } else {
    resolveFn(dicomFiles);
    store.commit("raiseSnackbar", {
      text: "Download Completed",
      color: "primary",
      timeout: 2000
    });
  }
};

// =============================
// Retrieve all dicom files ====
// =============================
const retrieve3rdPartySeries = (blobIds, nIds, jwt, dicomFiles) => {
  return new Promise(resolve => {
    download3rdPartyDicomFiles(blobIds, nIds, jwt, dicomFiles, resolve);
  });
};

// ====================
// Get dicom series ===
// ====================
const getDicomSeries = (uuid, jwt) => {
  return new Promise((resolve, reject) => {
    request
      .get(external3rdPartyURL + "dicom/" + uuid)
      .set("token", jwt)
      .query({ action: "scarica" })
      .responseType("blob")
      .then(resp => {
        if (process.env.NODE_ENV == "development") {
          console.log(resp);
        }
        resolve(resp);
      })
      .catch(error => reject(error));
  });
};

export const get3rdPartyDicomStudy = uuid => {
  return new Promise(resolve => {
    getExternalJWTToken(uuid).then(resp => {
      store.commit("raiseSnackbar", {
        text: "Downloading DICOM Study",
        color: "primary",
        timeout: -1
      });

      let token = resp.text;
      get3rdPartyDicomStudyInfo(uuid, token).then(resp => {
        let blobIds = Object.keys(resp.body);
        let dicomFiles = [];
        retrieve3rdPartySeries(blobIds, blobIds.length, token, dicomFiles).then(
          () => {
            resolve(dicomFiles);
          }
        );
      });
    });
  });
};

export const setup3rdPartyStudy = (studyId, parent) => {
  store.commit("raiseSnackbar", {
    text: "Connecting",
    color: "primary",
    timeout: -1
  });
  get3rdPartyDicomStudy(studyId).then(files => {
    parent.$ditto.dicom
      .parseFiles(files, defaultMetadata)
      .then(sharedData => {
        sharedData.forEach(serie =>
          parent.$ditto.dicom.storeSeriesStack(
            serie.larvitarSeriesInstanceUID,
            serie,
            false
          )
        );

        let stackData = sharedData.map(stack => {
          return defaultMetadata.reduce((obj, value) => {
            obj[value] = stack[value];
            return obj;
          }, {});
        });

        stackData = stackData.map((data, index) => {
          data.seriesInstanceUIDs = Object.keys(sharedData[index].instanceUIDs);
          return data;
        });

        parent.hasUploaded = false;
        parent.openViewer(stackData);
      })
      .catch(error => {
        console.error(error);
        parent.$store.commit("raiseSnackbar", {
          text: "Error while parsing retrieved data",
          color: "error",
          timeout: -1
        });
        return;
      });
  });
};
