import Vue from "vue";
import VueI18n from "vue-i18n";

Vue.use(VueI18n);

const messages = {
  en: {
    auth: {
      "reset-password": "reset password",
      noMDDisclaimer:
        "THIS VERSION IS NOT CERTIFIED AS A MEDICAL DEVICE FOR PRIMARY DIAGNOSIS. THERE ARE NO CERTIFICATIONS. IT IS POSSIBLE USE IT ONLY AS A REVIEWING AND SCIENTIFIC SOFTWARE, NOT FOR PRIMARY DIAGNOSTICS",
      cookiePolicy:
        "This site uses only technical cookies to improve user experience"
    },
    "dashboard-headers": {
      accession_number: "Accession N°",
      anonymized: "anonymized",
      study_date: "study date",
      birth_date: "birth date",
      name: "name",
      gender: "gender",
      has_patient_consent: "patient consent",
      patient: "patient",
      running_async_tasks: "# jobs",
      series: "# series",
      studies: "# instances",
      study_description: "study description",
      owner: "owner"
    },
    "dicom-import": {
      anonymize: "anonymize",
      "import-exams": "import exams",
      "metadata-x0008103e": "description",
      "metadata-x00080021": "date",
      "metadata-x00080031": "time",
      "metadata-x00080060": "modality",
      "metadata-x00180050": "thickness",
      "metadata-x00200011": "n",
      "metadata-x00280010": "rows",
      "metadata-x00280011": "cols",
      "metadata-larvitarNumberOfSlices": "instances",
      patient: "patient",
      size: "size"
    },
    "mask-editing": {
      title: "AI & Mask Editing",
      brush: "Activate Brush Tool (press CTRL to delete)",
      undo: "Undo Last Stroke",
      redo: "Redo Last Stroke",
      delete: "Delete Mask",
      close: "Close Editing"
    },
    "pacs-import": {
      "query-retrieve": "import exams",
      "metadata-QueryLevel": "Query Level",
      "metadata-AccessionNumber": "accession number",
      "metadata-StudyDate": "study date",
      "metadata-StudyTime": "study time",
      "metadata-InstitutionName": "institution name",
      "metadata-Modality": "modality",
      "metadata-NameOfPhysiciansReadingStudy": "physicians reading study",
      "metadata-PatientID": "patient ID",
      "metadata-PatientName": "patient name",
      "metadata-PatientBirthDate": "patient birthdate",
      "metadata-PatientSex": "patient sex",
      "metadata-ReferringPhysicianName": "physician name",
      "metadata-StudyDescription": "study description",
      "metadata-SeriesDescription": "series description"
    },
    predictors: {
      lungs: "Lungs",
      abdomen: "Abdomen",
      pancreas: "Pancreas",
      "lungs & nodules": "Lungs & Nodules",
      debug: "Debug Task",
      debugIdText: "Debug Id Text Task",
      totalSegmentator: "Total Body"
    },
    "try-again": "try again",
    "viewer-option": {
      show_metadata_overlay: "metadata information",
      show_orientation_markers: "orientation markers",
      show_scale_overlay: "scale markers",
      show_viewport_overlay: "viewport information",
      editing_layout_show_vr: "AI & mask editing: show VR"
    },
    "viewer-tool": {
      Angle: "angle",
      ArrowAnnotate: "annotation",
      Bidirectional: "diameter",
      Crosshair: "3D explorer",
      Crop: "Crop",
      EllipticalRoi: "elliptical ROI",
      Eraser: "eraser",
      StackScroll: "Stack scroll",
      FlipHorizontal: "flip (h)",
      FlipVertical: "flip (v)",
      FreehandRoi: "freehand ROI",
      Invert: "invert",
      Length: "length",
      mpr: "MPR",
      Pan: "pan",
      Probe: "probe",
      RectangleRoi: "measurement in rectangle ROI",
      DragProbe: "drag Probe",
      BorderMagnify: "Border Magnify",
      TextMarker: "Numeric Annotation",
      RectangleRoiOverlay: "rectangle ROI",
      reset: "reset",
      export: "export",
      print: "print",
      Rotate: "rotation",
      vr: "VR",
      Wwwc: "ww/wl",
      Zoom: "zoom",
      editing: "AI & mask editing",
      LengthPlot: "Sacroiliac joint",
      WwwcRegion: "WWwc from Region",
      WwwcRemoveRegion: "Remove Region from wwwc calc"
    },
    "viewer-colormaps": {
      Autumn: "Autumn",
      Blues: "Blues",
      Bone: "Bone",
      Cool: "Cool",
      CoolWarm: "CoolWarm",
      "COVID-19": "Covid-19",
      Copper: "Copper",
      Gray: "Gray",
      Hot: "Hot",
      "Hot Iron": "Hot Iron",
      "Hot Metal Blue": "Hot Metal Blue",
      HSV: "HSV",
      Jet: "Jet",
      "MUSCLE-BONE": "MuscleBone",
      PET: "PET",
      "PET 20 Step": "PET 20 Step",
      Spectral: "Spectral",
      Spring: "Spring",
      Summer: "Summer",
      Winter: "Winter"
    },
    "viewer-presets": {
      "CT: Abdomen": "CT: Abdomen",
      "CT: Bone": "CT: Bone",
      "CT: Cerebrum": "CT: Cerebrum",
      "CT: Covid-19": "CT: Covid-19",
      "CT: Liver": "CT: Liver",
      "CT: Lung": "CT: Lung",
      "CT: Mediastinum": "CT: Mediastinum",
      "CT: Pelvis": "CT: Pelvis"
    }
  },
  it: {}
};

const customTranslations = process.env.APP_CUSTOMER.translations;
if (customTranslations) {
  if (customTranslations.en) {
    messages.en = { ...messages.en, ...customTranslations.en };
  }

  if (customTranslations.it) {
    messages.it = { ...messages.it, ...customTranslations.it };
  }
}

const i18n = new VueI18n({
  silentTranslationWarn: true,
  locale: "en", // set locale
  fallbackLocale: "it", // set fallback locale
  messages // set locale messages
});

export default i18n;
