<template>
  <div class="py-2 text-center">
    <v-img
      class="mx-auto"
      contain
      :max-height="$vuetify.application.top - 15"
      max-width="60%"
      :src="logo"
    />
    <app-name />
    <span :class="getClass">{{ `v${version}` }}</span>
  </div>
</template>

<script>
import { version } from "@/../package.json";
import AppName from "@/components/AppName";

export default {
  name: "AppLogo",
  components: { AppName },
  props: {
    dark: { default: false, type: Boolean }
  },
  data() {
    return {
      version,
      logo: process.env.APP_CUSTOMER.upperLeftLogo
    };
  },
  computed: {
    getClass() {
      if (this.dark) {
        return "text-caption white--text";
      }
      return "text-caption";
    }
  }
};
</script>
