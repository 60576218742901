<template>
  <div class="h-100 d-flex flex-column">
    <!-- logo -->
    <app-logo :dark="darkMode" />

    <v-chip
      v-if="showDemoUserBadge()"
      class="mx-1 justify-center"
      color="accent"
      pill
      x-small
    >
      DEMO
    </v-chip>
    <v-chip
      v-if="showAdminUserBadge()"
      class="mx-1 justify-center"
      color="primary"
      pill
      x-small
    >
      ADMIN
    </v-chip>

    <!-- top menu -->
    <v-list :dark="darkMode" flat>
      <v-list-item
        v-if="wsOpenStatus !== wsStatus && !is3rdPartyUrl"
        class="mb-4"
      >
        <v-tooltip right>
          <template v-slot:activator="{ on, attrs }">
            <v-avatar class="mx-auto" color="red" tile v-bind="attrs" v-on="on">
              <v-icon dark :style="{ border: '1px solid' }">
                mdi-network-off-outline
              </v-icon>
            </v-avatar>
          </template>
          <span>{{ `Websocket is not connected (status=${wsStatus})` }}</span>
        </v-tooltip>
      </v-list-item>

      <!-- imports -->
      <div class="my-4">
        <!-- TODO close-on-click not working -->
        <!-- eager to automatically open import modal in free mode -->
        <v-menu
          content-class="menu-content"
          eager
          offset-x
          v-model="importExamsMenuOpen"
        >
          <template v-slot:activator="{ on, attrs }">
            <div v-bind="attrs" v-on="on">
              <v-list-item
                v-if="$route.name !== 'shared'"
                id="IM"
                :disabled="$isDemoUser"
              >
                <navigation-item
                  :item="{ icon: 'mdi-plus', name: 'import exams' }"
                  :disabled="$isDemoUser"
                />
              </v-list-item>
            </div>
          </template>
          <div v-if="!$isDemoUser" class="menu-content white-aura-of-coke">
            <v-list
              :dark="darkMode"
              dense
              :color="darkMode ? '#2c2c2c' : '#FFFFFF'"
              elevation="24"
            >
              <import-exams
                :dark="darkMode"
                @close-import-exams-menu="importExamsMenuOpen = false"
              />
              <import-pacs :dark="darkMode" />
            </v-list>
          </div>
          <div v-else>
            <subscription-alert />
          </div>
        </v-menu>
      </div>
      <div v-if="$route.name !== 'shared'">
        <template v-for="route in topRoutes">
          <v-menu
            content-class="menu-content"
            v-if="isDisabledRoute(route)"
            :key="route.name"
            offset-x
            tile
          >
            <template v-slot:activator="{ on, attrs }">
              <slot v-bind="{ on, attrs }">
                <div v-bind="attrs" v-on="on" class="menu-content">
                  <v-list-item
                    elevation="24"
                    class="px-1"
                    disabled
                    :to="{ name: route.name }"
                    two-line
                    :id="`NB-${route.name}`"
                  >
                    <component
                      v-if="route.mainMenuLinkComponent"
                      :is="route.mainMenuLinkComponent"
                      disabled
                      :item="route"
                    />
                    <navigation-item
                      :dark="darkMode"
                      v-else
                      disabled
                      :item="route"
                    />
                  </v-list-item>
                </div>
              </slot>
            </template>

            <subscription-alert />
          </v-menu>

          <v-list-item
            v-else
            :key="`else-${route.name}`"
            class="px-1"
            :to="{ name: route.name }"
            two-line
            :id="`NB-${route.name}`"
            :dark="darkMode"
            :disabled="isItemDisabled(route.name)"
          >
            <template v-slot="{ active }">
              <component
                v-if="route.mainMenuLinkComponent"
                :is="route.mainMenuLinkComponent"
                :active="active"
                :item="route"
              />
              <navigation-item :active="active" :item="route" />
            </template>
          </v-list-item>
        </template>
      </div>
      <div v-else>
        <v-list-item class="px-1" two-line :id="`NB-shared`">
          <template v-slot="{ active }">
            <navigation-item :active="active" :item="sharedMenuItem" />
          </template>
        </v-list-item>
      </div>
    </v-list>

    <!-- bottom menu -->
    <v-list class="mt-auto pb-0" :dark="darkMode" dense flat>
      <v-list-item class="px-1" :id="`NB-Switch-night`">
        <v-tooltip right>
          <template v-slot:activator="{ on }">
            <div v-on="on" class="text-center lh-small">
              <v-icon>mdi-theme-light-dark</v-icon>
              <v-switch
                class="text-center switch"
                :dark="darkMode"
                :color="darkMode ? 'primary' : 'white'"
                @change="toggleDarkMode"
                flat
              ></v-switch>
            </div>
          </template>
          <span>enable/disable dark mode</span>
        </v-tooltip>
      </v-list-item>
      <v-list-item
        v-if="!is3rdPartyUrl"
        class="px-1 mb-2"
        :disabled="$route.name !== 'shared' && isDisabledRoute(reportBugRoute)"
        :to="{ name: reportBugRoute.name, params: { buildId: buildId } }"
        two-line
        :id="`NB-${reportBugRoute.name}`"
      >
        <template v-slot="{ active }">
          <navigation-item :active="active" :item="reportBugRoute" />
        </template>
      </v-list-item>
      <v-list-item
        v-if="!is3rdPartyUrl"
        class="px-1 mb-2"
        :disabled="$route.name !== 'shared' && isDisabledRoute(helpRoute)"
        :to="{ name: helpRoute.name }"
        two-line
        :id="`NB-${helpRoute.name}`"
      >
        <template v-slot="{ active }">
          <navigation-item :active="active" :item="helpRoute" />
        </template>
      </v-list-item>

      <v-list-item class="px-1">
        <template v-slot="{ active }">
          <build-info :active="active" :item="buildInfo" />
        </template>
      </v-list-item>

      <!-- personal account panel -->
      <!-- TODO: resore this to activate settings page :settings-route-name="settingsRoute.name"
        :to="{ name: homeRoute.name }" -->
      <v-list-item
        v-if="$route.name === 'shared'"
        class="px-1"
        target="_blank"
        two-line
        :id="`NB-${homeRoute.name}`"
        @click="sharedLoginButtonClicked"
      >
        <template v-slot="{ active }">
          <navigation-item :active="active" :item="homeRoute" />
        </template>
      </v-list-item>
      <ditto-auth-account-panel
        :dark="darkMode"
        v-else-if="!is3rdPartyUrl"
        direction="right"
      >
        <template v-slot="{ on, attrs }">
          <v-list-item
            class="account-list-item px-1"
            two-line
            v-bind="attrs"
            v-on="on"
            :id="`NB-${accountRoute.name}`"
          >
            <navigation-item :item="accountRoute" />
          </v-list-item>
        </template>
      </ditto-auth-account-panel>
      <!-- customer logo -->
      <customer-logo />
    </v-list>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { statusSource as websocketStatusSource } from "@/js/api.series.download";

import AppLogo from "@/components/AppLogo";
import CustomerLogo from "@/components/CustomerLogo";
import ImportExams from "@/components/ImportExams";
import ImportPacs from "@/components/ImportPacs.vue";
import NavigationItem from "@/components/NavigationItem";
import SubscriptionAlert from "@/components/SubscriptionAlert";
import buildData from "@/../BUILD_ID.json";
import BuildInfo from "@/components/Info.vue";

export default {
  name: "NavigationBar",
  components: {
    AppLogo,
    CustomerLogo,
    ImportExams,
    ImportPacs,
    NavigationItem,
    SubscriptionAlert,
    BuildInfo
  },
  data() {
    return {
      settingsRoute: {
        name: "settings"
      },
      reportBugRoute: {
        name: "report bug",
        icon: "mdi-bug"
      },
      helpRoute: {
        name: "help",
        icon: "mdi-help-circle"
      },
      homeRoute: {
        name: "login",
        icon: "mdi-home-account"
      },
      sharedMenuItem: {
        name: "shared link",
        icon: "mdi-image-filter-black-white"
      },
      buildInfo: {
        name: "info",
        icon: "mdi-information-outline"
      },
      topRoutes: this.$router.options.routes.filter(r => r.mainMenuLink),
      wsOpenStatus: WebSocket.OPEN,
      wsStatus: null,
      wsSubscription: null,
      is3rdPartyUrl: process.env.APP_CUSTOMER.getExternal3rdPartyURL,
      importExamsMenuOpen: false,
      buildId: buildData ? buildData.build_id : null
    };
  },
  beforeMount() {
    if (this.is3rdPartyUrl) {
      this.topRoutes = this.topRoutes.filter(route => route.name == "viewer");
    }
  },
  mounted() {
    // listen to ws connection status changes
    this.wsSubscription = websocketStatusSource.subscribe({
      next: value => (this.wsStatus = value)
    });
  },
  beforeDestroy() {
    if (this.wsSubscription) {
      this.wsSubscription.unsubscribe();
    }
  },
  computed: {
    ...mapGetters("auth", ["isAuth"]),
    ...mapGetters(["darkMode"]),
    ...mapGetters("dashboard", ["numberOfSelectedSeries"]),
    accountRoute() {
      return {
        name: "account",
        icon: this.isAuth ? "mdi-account" : "mdi-account-lock"
      };
    }
  },
  methods: {
    toggleDarkMode() {
      this.$store.commit("toggleDarkMode");
    },
    isDisabledRoute(route) {
      // Navbar item disable rule
      if (route.disabled) {
        return true;
      }
      if (this.$route.name === "shared") {
        return true;
      }
      // Auth route
      const r = this.$router.options.routes.find(
        ({ name }) => name == route.name
      );

      if (!r) {
        return true;
      }

      const authRequired = r.meta ? r.meta.auth : false;
      return authRequired ? !this.isAuth : false;
    },
    showDemoUserBadge() {
      return this.$isDemoUser && this.$route.name !== "shared";
    },
    showAdminUserBadge() {
      return this.$isAdminUser && this.$route.name !== "shared";
    },
    sharedLoginButtonClicked() {
      this.$store.dispatch("auth/logout");
      this.$router.push({ name: this.homeRoute.name });
    },
    isItemDisabled(routeName) {
      if (routeName == "viewer" && !this.numberOfSelectedSeries) return true;
      return false;
    }
  }
};
</script>

<style lang="scss" scoped>
.v-navigation-drawer {
  font-family: $highlights-font-family;
}

.v-list-item:not(.v-list-item--active):hover {
  opacity: 0.7;
}

.v-list-item--active {
  background: white;
}

.account-list-item[aria-expanded="true"] {
  opacity: 0.7;
}
</style>

<style lang="scss">
.header-group {
  height: 100%;
  display: flex;
  flex-direction: column;

  .header-title {
    height: 1rem;
    overflow: hidden;
    text-align: center;
    text-transform: uppercase;

    // text-caption
    font-size: xx-small;
    font-weight: 100;
  }

  .header-content {
    display: flex;
    padding: 0 0.5em;
    margin: auto;
  }
}
.switch {
  margin-top: 2px;
  margin-bottom: 2px;
}
.menu-content {
  background-color: #1e1e1e;
}
.mobile-menu-content {
  max-height: calc(100% - 80px);
  overflow: auto;
}
</style>
