<template>
  <div :class="getClass">
    <div>dicom</div>
    <div>vision</div>
  </div>
</template>

<script>
export default {
  name: "AppName",
  props: {
    dark: {
      default: false,
      type: Boolean
    }
  },
  computed: {
    getClass() {
      if (this.dark) {
        return "text lh-small white--text";
      }
      return "text lh-small white--text";
    }
  }
};
</script>

<style lang="scss" scoped>
.text {
  font-family: $body-font-family;
  text-transform: uppercase;

  & > div:first-child {
    font-weight: 600;
  }

  & > div:last-child {
    font-weight: 300;
  }

  margin-top: 5px;
}
</style>
