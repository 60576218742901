import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

import dashboardModule from "./modules/dashboard";
import viewerModule from "./modules/viewer";

export default new Vuex.Store({
  state: {
    overlay: { show: false, text: "" },
    snackbar: { show: false, text: "", color: "", timeout: -1 },
    darkMode: true,
    notificationQueue: []
  },
  getters: {
    darkMode: state => state.darkMode
  },

  // Call raiseSnackbar with "disaptch" to enqueue the message
  // Call raiseSnackbar with "commit" to immediatly show the message
  mutations: {
    closeSnackbar: state =>
      (state.snackbar = { show: false, text: "", color: "" }),
    enqueueSnackbar: (state, notification) => {
      state.notificationQueue.push(notification);
    },
    showSnackbar: (state, { text, color, timeout }) => {
      state.snackbar = { show: true, text, color, timeout: timeout || -1 };
    },
    raiseSnackbar: (state, { text, color, timeout }) =>
      (state.snackbar = { show: true, text, color, timeout: timeout || -1 }),
    toggleOverlay: (state, { show, text }) => {
      state.overlay.show = show;
      state.overlay.text = text ? text : "";
    },
    toggleDarkMode: state => {
      state.darkMode = !state.darkMode;
    }
  },
  actions: {
    raiseSnackbar({ commit, state }, notification) {
      commit("enqueueSnackbar", notification);
      if (state.notificationQueue.length === 1) {
        this.dispatch("processSnackbarQueue");
      }
    },
    processSnackbarQueue({ commit, state }) {
      if (state.notificationQueue.length === 0) return;

      const currentNotification = state.notificationQueue[0];
      commit("showSnackbar", currentNotification);

      setTimeout(() => {
        commit("closeSnackbar");
        state.notificationQueue.shift();
        if (state.notificationQueue.length > 0) {
          this.dispatch("processSnackbarQueue");
        }
      }, currentNotification.timeout || -1);
    },
    closeSnackbar({ commit, state }) {
      commit("closeSnackbar");
      setTimeout(() => {
        if (state.notificationQueue.length > 0) {
          state.notificationQueue.shift();
          if (state.notificationQueue.length > 0) {
            this.dispatch("processSnackbarQueue");
          }
        }
      }, 100);
    }
  },
  modules: {
    dashboard: dashboardModule,
    viewer: viewerModule
  }
});
