import "@mdi/font/css/materialdesignicons.css";
import Vue from "vue";
import Vuetify from "vuetify/lib/framework";
Vue.use(Vuetify);

const commonColors = {
  // utils
  anchor: "#0375B4",
  error: "#F25324",
  info: "#0375B4",
  success: "#339955",
  warning: "#F2911B",
  // custom
  // black: "#262228",
  black: "#000000", // match with dicom vision logo background
  grey: "#F5F5F5",
  dicomtool: "#48A4A7" // http://colormind.io/ - using primary and accent from logo
};

// TODO review
// logo yellow: #E9E60F
// logo blue: #4395A3
const logoTheme = {
  ...commonColors,
  primary: "#4395A3",
  anchor: "#4395A3",
  accent: "#F0BD4A"
};

export default new Vuetify({
  // https://vuetifyjs.com/en/features/breakpoints/#breakpoint-service
  breakpoint: {
    thresholds: {
      md: 1540
    },
    scrollBarWidth: 0
  },
  theme: {
    dark: false,
    options: { customProperties: true },
    themes: {
      light: logoTheme
    }
  }
});
