import Vue from "vue";
import VueRouter from "vue-router";
import goTo from "vuetify/es5/services/goto";
import NavigationBar from "@/components/NavigationBar";
Vue.use(VueRouter);

// Dynamic components
// TODO use this: https://vuejs.org/v2/guide/components-dynamic-async.html#Handling-Loading-State
const OpenViewerLink = () => import("@/components/OpenViewerLink");
const DashboardBar = () => import("@/views/DashboardBar");
const Dashboard = () => import("@/views/Dashboard");
const Viewer = () => import("@/views/Viewer");
const SharedViewerLoader = () => import("@/views/SharedViewerLoader");
const ViewerBar = () => import("@/views/ViewerBar");
const ViewerNav = () => import("@/views/ViewerNav");
const ExternalUpload = () => import("@/components/ExternalUpload");
// const routerViewDefault = { template: `<router-view/>` };

const routes = [
  // redirect all not matching routes to "/exams"

  {
    path: "*",
    redirect: "/exams"
  },
  {
    path: "/exams",
    name: "exams",
    // private section (auth users only)
    meta: { auth: true, autoLogin: true },
    mainMenuLink: true,
    components: {
      default: Dashboard,
      bar: DashboardBar,
      sidebar: NavigationBar
    },
    icon: "mdi-view-list-outline"
  },
  {
    path: "/viewer/:externalStudyId?",
    components: {
      default: Viewer,
      bar: ViewerBar,
      nav: ViewerNav,
      sidebar: NavigationBar
    },
    // both private and guest users allowed
    meta: { autoLogin: true },
    name: "viewer",
    mainMenuLink: true,
    mainMenuLinkComponent: OpenViewerLink,
    children: [
      {
        path: "shared",
        name: "sharedId",
        meta: { autoLogin: false },
        mainMenuLink: false,
        component: SharedViewerLoader
      }
    ],
    icon: "mdi-image-filter-black-white"
  },
  {
    path: "/shared/:sharedId",
    name: "shared",
    meta: { autoLogin: false },
    mainMenuLink: false,
    mainMenuLinkComponent: OpenViewerLink,
    components: {
      default: SharedViewerLoader,
      bar: ViewerBar,
      nav: ViewerNav,
      sidebar: NavigationBar
    }
  },
  {
    path: "/upload/:uploadId",
    name: "upload",
    meta: { autoLogin: false },
    mainMenuLink: false,
    components: {
      default: ExternalUpload
    }
  },
  {
    path: "/report-bug",
    name: "report bug",
    meta: { auth: false, autoLogin: false },
    components: {
      default: null
    },
    beforeEnter(to) {
      window.open(
        `${process.env.APP_CUSTOMER.serviceDeskUrl}/portal/2/group/2/create/11?customfield_10107=${to.params.buildId}`,
        "_blank"
      );
    }
  },
  {
    path: "/help",
    name: "help",
    meta: { auth: false, autoLogin: false },
    components: {
      default: null
    },
    beforeEnter() {
      window.open(
        `${process.env.APP_CUSTOMER.serviceDeskUrl}/portals`,
        "_blank" // This is what makes it open in a new window.
      );
    }
  },
  {
    path: "/settings",
    name: "settings",
    // private section (auth users only)
    meta: { auth: true, autoLogin: true },
    components: {
      default: null,
      sidebar: NavigationBar
    },
    children: [
      {
        path: "account",
        name: "account",
        components: {
          default: null
        }
      }
    ]
  },
  // Register customer navbar routes
  ...process.env.APP_CUSTOMER.routes.map(r => ({
    ...r,
    path: `/${process.env.APP_CUSTOMER_NAME}/${r.name}`,
    name: `${process.env.APP_CUSTOMER_NAME}-${r.name}`,
    meta: r.meta,
    components: {
      sidebar: NavigationBar,
      ...Object.keys(r.components || {}).reduce((obj, key) => {
        obj[key] = () =>
          import(
            `@/customers/${process.env.APP_CUSTOMER_NAME}/${r.components[key]}`
          );
        return obj;
      }, {})
    }
  }))
];

if (process.env.APP_CUSTOMER.getExternal3rdPartyURL) {
  routes[0].redirect = "viewer";
  routes.push({
    path: "/",
    redirect: "viewer"
  });
  routes.push({
    path: "/login",
    redirect: "viewer"
  });
}

const router = new VueRouter({
  // mode: "history",
  routes,
  scrollBehavior: (to, from, savedPosition) => {
    let scrollTo = 0;

    if (to.hash) {
      scrollTo = to.hash;
    } else if (savedPosition) {
      scrollTo = savedPosition.y;
    }

    return goTo(scrollTo);
  }
});

export default router;
