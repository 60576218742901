<template>
  <div class="text-center lh-small">
    <v-icon :color="iconColor" :disabled="disabled">
      {{ item.icon }}
    </v-icon>
    <div>
      <b :class="`${iconColor}--text${disabled ? '--disabled' : ''}`">{{
        item.name
      }}</b>
    </div>
  </div>
</template>

<script>
export default {
  name: "NavigationItem",
  props: {
    active: { default: false, type: Boolean },
    disabled: { required: false, type: Boolean },
    item: { required: true, type: Object }
  },
  computed: {
    iconColor() {
      return this.active ? "primary" : "white";
    }
  }
};
</script>
