import Vue from "vue";
import {
  CanvasTypes,
  disabledTools,
  viewerInteractionsTools,
  viewerMeasurementsTools,
  viewerViewportActions,
  viewerViewportOptions,
  customerSpecificMeasurementTools,
  wwwcTools
} from "@/js/preferences";
import { EventBus } from "@/js/eventBus";

const allowToDisableTools = false;

const getInitialState = () => ({
  activeViewportIndex: -1,
  colormapId: null,
  layout: null,
  data: [], // data grouped by studies
  options: viewerViewportOptions || [],
  previousStackLayout: null,
  previousStackViewports: null,
  selectedTool: null,
  reportMode: false,
  reportEditing: null,
  tools: {
    interaction: viewerInteractionsTools,
    wwwcTools: wwwcTools,
    measurement: viewerMeasurementsTools.map(tool => {
      const replacementTool = customerSpecificMeasurementTools.find(
        replacement => replacement.name === tool.name
      );
      if (replacementTool) {
        // Update tool configuration
        return {
          ...tool,
          configuration: {
            ...tool.configuration,
            ...replacementTool.configuration
          }
        };
      } else {
        return tool;
      }
    })
  },
  viewportActions: viewerViewportActions,
  viewports: [],
  server: false,
  predictions: {
    predictors: [],
    jobs: {}
  },
  activeMask: null,
  annotations: {},
  layoutSwitching: false,
  sharedId: null,
  collabTooltip: "",
  toolsToEnableForCachedSeries: []
});

// Called as cleanup when there are no data selected.
// Call clearSeriesData and deleteViewport for viewports not in data anymore
const clearViewportsStore = (state, data) => {
  state.viewports.forEach(v => {
    if (!v) {
      return;
    }

    const study = data.find(d => d.id == v.study);
    if (study) {
      const series = study.series.find(d => d.id == v.series);
      if (!series) {
        // cleanings for v
        Vue.prototype.$ditto.dicom.clearSeriesData(v.paneId, v.series);
        Vue.prototype.$ditto.dicom.deleteViewport(v.paneId);
        Vue.set(state, "annotations", {});
        Vue.set(state, "toolsToEnableForCachedSeries", []);
      }
    } else {
      // cleanings for v
      Vue.prototype.$ditto.dicom.clearSeriesData(v.series);
      Vue.prototype.$ditto.dicom.deleteViewport(v.paneId);
      Vue.set(state, "toolsToEnableForCachedSeries", []);
    }
  });
};

// Recursively count the number of panes of a layout
const countPanes = layout => {
  return layout.panes.reduce((sum, pane) => {
    if (pane.panes) {
      sum += countPanes(pane);
    } else {
      sum++;
    }
    return sum;
  }, 0);
};

// Recursively get panes
const getRenderedPanes = layout => {
  let res = layout.panes.reduce((panes, pane) => {
    if (pane.panes) {
      panes = panes.concat(getRenderedPanes(pane));
    } else {
      panes = panes.concat(pane);
    }
    return panes;
  }, []);

  return res;
};

// Set active viewport to the first not null viewport index or -1
const resetActiveViewport = state => {
  state.activeViewportIndex = state.viewports.findIndex(v => !!v);
};

/*
// Check if a tool is enabled for a specific canvas
const isToolEnabled = (canvasType, toolName) => {
  const enabledTools = [
    ...viewerInteractionsTools,
    ...viewerMeasurementsTools
  ].filter(({ name }) => !disabledTools[canvasType].find(v => v == name));

  return enabledTools.find(({ name }) => name == toolName);
};
*/

// Activate a tool
const activateTool = (tool, panes, options) => {
  panes.forEach(pane => {
    const isDisabled = disabledTools[pane.type].find(v => v == tool.name);
    if (isDisabled) {
      return;
    }

    switch (pane.type) {
      case CanvasTypes.stack: {
        Vue.prototype.$ditto.dicom.activateTool(tool, [pane.id], options);
        break;
      }

      case CanvasTypes.mpr: {
        EventBus.$emit("mpr-activate-tool", tool.name);
        break;
      }

      case CanvasTypes.vr: {
        EventBus.$emit("vr-activate-tool", tool.name);
        break;
      }
    }
  });
};

// Disable tool on panes
const disableTool = (tool, panes) => {
  panes.forEach(pane => {
    const isDisabled = disabledTools[pane.type].find(v => v == tool.name);
    if (isDisabled) {
      return;
    }

    switch (pane.type) {
      case CanvasTypes.stack: {
        Vue.prototype.$ditto.dicom.disableTool(tool, [pane.id]);
        break;
      }

      /*
      case CanvasTypes.mpr: {
        EventBus.$emit("mpr-disable-tool", tool.name);
        break;
      }

      case CanvasTypes.vr: {
        EventBus.$emit("vr-disable-tool", tool.name);
        break;
      }
      */
    }
  });
};

export default {
  namespaced: true,
  state: getInitialState(),
  getters: {
    // Active viewport layout name and pane type
    activeLayoutPane: (state, getters) => {
      const viewport = getters.viewport(state.activeViewportIndex);
      if (viewport && state.layout) {
        const pane =
          state.layout.panes.find(p => p.id == viewport.paneId) || {};
        return { layoutName: state.layout.name, canvasType: pane.type };
      }
      return null;
    },
    // Number of panes of the current layout
    numberOfPanes: state => countPanes(state.layout),
    // Data structure used to render the navigation panel
    panel: state => {
      // Add a patient layer on top of state.data
      return state.data.reduce((result, study) => {
        // omit patient data from study
        // eslint-disable-next-line no-unused-vars
        let { patient, ..._study } = study;
        _study.series = _study.series.map(s => ({
          id: s.id,
          promise: s.promise
        }));

        if (!result[study.patient.id]) {
          result[study.patient.id] = {
            ...study.patient,
            studies: [_study]
          };
        } else {
          result[study.patient.id].studies.push(_study);
        }
        return result;
      }, {});
    },
    // Get series information from state.data
    seriesData: state => viewport => {
      if (!viewport) {
        return;
      }

      const { study, series } = viewport;
      let result;

      try {
        result = state.data.find(d => d.id == study);
        const workgroup = result.workgroup;

        result = result.series.find(s => s.id == series);
        result.workgroup = workgroup;
      } catch (error) {
        console.warn(error);
      }

      return result;
    },
    studyData: state => viewport => {
      if (!viewport) {
        return;
      }

      const { study } = viewport;
      return state.data.find(d => d.id == study);
    },
    activeViewportStackReady: (state, getters) => {
      if (state.activeViewportIndex < 0) {
        return false;
      }
      const viewport = state.viewports[state.activeViewportIndex];
      const data = getters.seriesData(viewport);
      // series upload is completed and series is fully downloaded from server
      return data ? data.upload_completed && data.download_completed : false;
    },
    // Get viewport by index
    viewport: state => index => state.viewports[index],
    // Get viewport index by series id
    viewportIndex: state => id =>
      state.viewports.findIndex((v = {}) => v.series == id),
    selectedToolIndex: state => type =>
      state.selectedTool
        ? state.tools[type].findIndex(
            ({ name }) => name == state.selectedTool.name
          )
        : -1,
    predictorByJob: state => jobId => {
      const job = state.predictions.jobs[jobId];
      if (job) {
        return state.predictions.predictors.find(p => p.id === job.predictorId);
      }
    },
    jobProgress: state => jobId => {
      if (
        state.predictions.jobs[jobId] &&
        state.predictions.jobs[jobId].task_info
      ) {
        const taskInfo = state.predictions.jobs[jobId].task_info;
        return Math.round((100 * taskInfo.done) / taskInfo.total);
      } else {
        return 0;
      }
    },
    jobsBySeries: state => seriesId => {
      return Object.keys(state.predictions.jobs).reduce((list, jobId) => {
        if (state.predictions.jobs[jobId].seriesId === seriesId) {
          list.push({ ...state.predictions.jobs[jobId], id: jobId });
        }
        return list;
      }, []);
    },
    annotationForImageID: state => imageID => {
      if (state.annotations && imageID) {
        if (imageID in state.annotations) {
          return state.annotations[imageID];
        }
      }
    },
    isCollabEnabled(state, _getters, rootState, rootGetters) {
      // demo -> disabilitato
      // shared -> abilitato
      // utente non loggato -> disabilitato
      // setting del customer enabledFeatures.collaborative a false -> disabilitato
      // altrimenti disablitato
      // check root getters invece di state.auth.user.is_demo
      return (
        rootGetters["auth/isAuth"] && // loggato
        (!rootState.auth.user.is_demo || state.sharedId !== null) && // non demo o link sharato
        process.env.APP_CUSTOMER.enabledFeatures.collaborative // feature abilitata nel customer file
      );
    },
    isTotsegEnabled: () => {
      return process.env.APP_CUSTOMER.enabledFeatures.autoSeg;
    },
    isManualSegEnabled: (state, _getters, rootState, rootGetters) => {
      return (
        rootGetters["auth/isAuth"] &&
        !rootState.auth.user.is_demo &&
        process.env.APP_CUSTOMER.enabledFeatures.manualSeg
      );
    },
    isDeepcEnabled: () => {
      return process.env.APP_CUSTOMER.enabledFeatures.deepc;
    },
    isSharedLink: state => state.sharedId !== null
  },
  mutations: {
    // Replace viewer data with new data
    initData: (state, data) => {
      clearViewportsStore(state, data);
      state.layout = null;
      state.viewports = [];
      state.data = data;
    },
    // Add new data to viewer data
    updateData: (state, data) => {
      state.data = [...state.data, ...data];
    },
    reset: state => {
      const s = getInitialState();
      Object.keys(s).forEach(key => (state[key] = s[key]));
    },
    // Set active viewport index
    setActiveViewport: (state, index) => (state.activeViewportIndex = index),
    setColormapId: (state, colormapId) => (state.colormapId = colormapId),
    setServer: (state, value) => (state.server = value),
    setReportEdit: (state, reportObj) => (state.reportEditing = reportObj),
    clearReportEdit: state => (state.reportEditing = null),
    setOption: (state, { option, value }) => {
      const index = state.options.findIndex(({ name }) => name == option.name);
      if (index < 0) {
        console.warn(`Viewer option with name ${option.name} not found`);
        return;
      }

      Vue.set(state.options[index], "value", value);

      switch (option.type) {
        case "tool": {
          const renderedPanes = getRenderedPanes(state.layout);
          if (!renderedPanes.length) {
            break;
          }

          if (value) {
            Vue.prototype.$ditto.dicom.showTool(
              option.tool,
              renderedPanes.map(p => p.id)
            );
          } else {
            Vue.prototype.$ditto.dicom.hideTool(
              option.tool,
              renderedPanes.map(p => p.id)
            );
          }

          break;
        }
      }
    },
    initOptions: (state, payload) => {
      Object.keys(payload).forEach(key => {
        if (viewerViewportOptions.find(o => o.name === key)) {
          const index = state.options.findIndex(({ name }) => name == key);
          Vue.set(state.options[index], "value", payload[key]);
        }
      });
    },
    initViewerSessionId: state => {
      const uuid = "10000000-1000-4000-8000-100000000000".replace(/[018]/g, c =>
        (
          c ^
          (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
        ).toString(16)
      );
      Vue.set(state, "viewerSessionId", uuid); // todo replace with session id (when session based )
    },
    // Change viewer layout
    updateLayout: (
      state,
      {
        layoutSwitching = false,
        resetViewports = true,
        restoreViewports = false,
        ...data
      }
    ) => {
      let paneIndex = -1;
      let getViewportTarget = null;

      state.layoutSwitching = layoutSwitching;

      if (resetViewports) {
        // also automatically assign data to viewports
        state.viewports = [];

        // Series not already in viewports
        const unassignedData = state.data.reduce((result, study) => {
          const unassignedSeries = study.series.filter(
            s => !state.viewports.find((v = {}) => v.series == s.id)
          );
          if (unassignedSeries.length) {
            result.push({ ...study, series: unassignedSeries });
          }
          return result;
        }, []);

        // Assign to each panel a series stored in state.data
        let studyIndex = 0;
        let seriesIndex = 0;

        getViewportTarget = () => {
          if (state.viewports[paneIndex]) {
            // Already assigned viewport
            return state.viewports[paneIndex];
          }

          // Assign first series NOT already in viewports
          let result;
          if (unassignedData[studyIndex]) {
            const s = unassignedData[studyIndex].series[seriesIndex];
            if (unassignedData[studyIndex].series[seriesIndex]) {
              result = {
                series: s.id,
                study: unassignedData[studyIndex].id,
                patient: unassignedData[studyIndex].patient.id
              };
              seriesIndex++;
            } else {
              studyIndex++;
              seriesIndex = 0;
              return getViewportTarget();
            }
          }
          return result;
        };
      }

      if (restoreViewports) {
        state.viewports = [...state.previousStackViewports];
      }

      const iteratePanes = panes => {
        return panes.map(pane => {
          if (pane.panes) {
            return {
              ...pane,
              panes: iteratePanes(pane.panes)
            };
          } else {
            paneIndex++;

            // Assign an id to the pane
            pane.id = pane.id || `canvas-${paneIndex}`;

            // Assign data to viewport
            if (resetViewports) {
              const target = getViewportTarget();
              if (target) {
                Vue.set(state.viewports, paneIndex, {
                  ...target,
                  paneId: pane.id
                });
              }
            }

            // Set index to pane
            return {
              ...pane,
              index: paneIndex
            };
          }
        });
      };

      // Set layout
      state.layout = { ...data, panes: iteratePanes(data.panes) };

      // Set active viewport
      resetActiveViewport(state);
    },
    setViewports: (state, viewports) => {
      state.viewports = viewports;
    },
    updatePreviousLayout: (state, layout) =>
      (state.previousStackLayout = layout),
    updatePreviousViewports: (state, list) =>
      (state.previousStackViewports = list),
    // Update the data of a series assigned to a viewport (eg series promise resolved)
    updateSeries: (state, data) => {
      try {
        const studyData = state.data.find(s => s.id == data.study.id);
        const seriesIndex = studyData.series.findIndex(s => s.id == data.id);
        // eslint-disable-next-line no-unused-vars
        const { promise, study, ...seriesData } = {
          ...studyData.series[seriesIndex],
          ...data,
          download_completed: false
        };
        Vue.set(studyData.series, seriesIndex, seriesData);
      } catch (error) {
        console.warn(error);
      }
    },
    downloadEnded: (state, { seriesId, value = true }) => {
      const study = state.data.find(d => {
        return d.series.find(s => s.id == seriesId);
      });

      if (study) {
        const seriesData = study.series.find(s => s.id == seriesId);
        if (seriesData) {
          Vue.set(seriesData, "download_completed", value);
        }
      }
    },
    uploadEnded: (state, seriesId) => {
      const study = state.data.find(d => {
        return d.series.find(s => s.id == seriesId);
      });

      if (study) {
        const seriesData = study.series.find(s => s.id == seriesId);
        if (seriesData) {
          Vue.set(seriesData, "upload_completed", true);
        }
      }
    },
    // Assign a series to a different viewport
    updateViewportIndex: (state, { index, viewport }) => {
      // Clear required viewport
      if (!viewport) {
        Vue.set(state.viewports, index, undefined);
        if (state.activeViewportIndex == index) {
          resetActiveViewport(state);
        }
        return;
      }

      const currentIndex = state.viewports.findIndex(
        (v = {}) => v.series == viewport.series
      );
      if (currentIndex === index) {
        return;
      }

      // save pane id
      viewport.paneId = state.viewports[index]
        ? state.viewports[index].paneId
        : "canvas-0";

      // Remove prev viewport
      if (currentIndex >= 0) {
        Vue.set(state.viewports, currentIndex, undefined);
      }

      Vue.set(state.viewports, index, viewport);
      Vue.set(state, "activeViewportIndex", index);
    },
    updateSelectedTool: (state, tool) => {
      const renderedPanes = getRenderedPanes(state.layout);

      const previousTool = state.selectedTool
        ? { ...state.selectedTool }
        : null;
      if (tool) {
        // add tool
        state.selectedTool = tool;
        activateTool(state.selectedTool, renderedPanes);
      } else if (allowToDisableTools) {
        // remove previous selection
        state.selectedTool = null;

        if (previousTool && (!tool || tool.name != previousTool.name)) {
          disableTool(previousTool, renderedPanes);

          // preserve options of default active tools
          if (previousTool.defaultActive) {
            activateTool(previousTool, renderedPanes, {});
          }
        }
      }
    },
    addMasksToSeries: (state, { seriesId, masksInfo }) => {
      if (masksInfo.length === 0) {
        return;
      }

      try {
        // Find the study containing the series
        const study = state.data.find(d =>
          d.series.some(s => s.id === seriesId)
        );

        if (!study) {
          console.warn(`Study not found for seriesId ${seriesId}`);
          return;
        }

        // Find the series within the study
        const seriesData = study.series.find(s => s.id === seriesId);

        if (!seriesData) {
          console.warn(`Series data not found for seriesId ${seriesId}`);
          return;
        }

        // Initialize masks_info array if it doesn't exist
        if (!seriesData.masks_info) {
          seriesData.masks_info = [];
        }

        // Remove and re-add every entry already present
        seriesData.masks_info = seriesData.masks_info
          .filter(existingMask => {
            return !masksInfo.some(
              newMask => newMask.value === existingMask.value
            );
          })
          .concat(masksInfo);
      } catch (error) {
        console.error("Error adding masks to series:", error);
      }
    },
    removeMasksFromSeries: (state, { seriesId, masksInfo }) => {
      if (masksInfo.length === 0) {
        return;
      }

      try {
        // Find the study containing the series
        const study = state.data.find(d =>
          d.series.some(s => s.id === seriesId)
        );

        if (!study) {
          console.warn(`Study not found for seriesId ${seriesId}`);
          return;
        }

        // Find the series within the study
        const seriesData = study.series.find(s => s.id === seriesId);

        if (!seriesData) {
          console.warn(`Series data not found for seriesId ${seriesId}`);
          return;
        }

        // Check if masks_info array exists
        if (!seriesData.masks_info) {
          return; // No masks to remove
        }

        // Remove masks with matching values
        seriesData.masks_info = seriesData.masks_info.filter(existingMask => {
          return !masksInfo.some(
            maskToRemove => maskToRemove.value === existingMask.value
          );
        });
      } catch (error) {
        console.error("Error removing masks from series:", error);
      }
    },
    initPredictors: (state, predictors) => {
      Vue.set(state.predictions, "predictors", predictors);
    },
    addJob: (state, { predictorId, jobId, seriesId }) => {
      Vue.set(state.predictions.jobs, jobId, {
        predictorId,
        seriesId
      });
    },
    setJobStatus: (state, { jobId, status }) => {
      Vue.set(state.predictions.jobs, jobId, {
        ...state.predictions.jobs[jobId], // preserve predictorId, seriesId
        ...status
      });
    },
    removeJob: (state, jobId) => {
      Vue.delete(state.predictions.jobs, jobId);
    },
    setActiveMask: (state, mask) => {
      Vue.set(state, "activeMask", mask);
    },
    setOnlineUsersOnSeries: (state, { seriesId, numOnlineUsers }) => {
      const study = state.data.find(item =>
        item.series.some(series => series.id === seriesId)
      );
      const seriesIdx = study.series.findIndex(
        series => series.id === seriesId
      );
      Vue.set(study.series[seriesIdx], "numOnlineUsers", numOnlineUsers);
    },
    setAnnotations: (state, { imageId, annotations }) => {
      let groupedAnnotations = {};
      for (let annObj of annotations) {
        let toolName = Object.keys(annObj)[0];
        let newAnnotation = annObj[toolName];
        if (!(toolName in groupedAnnotations)) {
          groupedAnnotations[toolName] = [];
        }
        // Check if there is an existing annotation with the same UUID
        let indexToUpdate = groupedAnnotations[toolName].findIndex(
          existingAnn => existingAnn.uuid === newAnnotation.uuid
        );

        if (indexToUpdate !== -1) {
          // Replace the existing annotation with the new one
          groupedAnnotations[toolName][indexToUpdate] = newAnnotation;
        } else {
          // If no matching UUID found, simply push the new annotation
          groupedAnnotations[toolName].push(newAnnotation);
        }
      }
      Vue.set(state.annotations, imageId, groupedAnnotations);
    },
    removeAnnotation: (state, { imageId, toolName, annotationId }) => {
      const annotations = state.annotations;
      if (annotations && annotations[imageId]) {
        const annotationForImageID = annotations[imageId];
        if (annotationForImageID && annotationForImageID[toolName]) {
          // remove annotation
          const indexOfAnnotation = annotationForImageID[toolName].findIndex(
            item => item.uuid === annotationId
          );
          const annotationsForImageIdForTool = annotationForImageID[toolName];
          annotationsForImageIdForTool.splice(indexOfAnnotation, 1);
          if (annotationsForImageIdForTool.length > 0) {
            annotationForImageID[toolName] = annotationsForImageIdForTool;
            Vue.set(state.annotations, imageId, annotationForImageID);
          } else {
            delete annotationForImageID[toolName];
            Vue.set(state.annotations, imageId, annotationForImageID);
          }
        }
      }
    },
    resetLayoutSwitching: state => {
      state.layoutSwitching = null;
    },
    setSharedId: (state, { sharedId }) => {
      state.sharedId = sharedId;
    },
    clearAnnotationStore: (state, { imageIds }) => {
      imageIds.forEach(imageId => {
        delete state.annotations[imageId];
      });
    },
    updateAnnotation: (state, { imageId, toolName, annotation }) => {
      const annotations = state.annotations;
      if (annotations && annotations[imageId]) {
        const annotationForImageID = annotations[imageId];
        if (annotationForImageID && annotationForImageID[toolName]) {
          // remove annotation
          const indexOfAnnotation = annotationForImageID[toolName].findIndex(
            item => item.uuid === annotation.uuid
          );
          annotationForImageID[toolName].splice(indexOfAnnotation, 1);
          annotationForImageID[toolName].push(annotation);
        }
      }
    },
    setCollabTooltip: (state, { message }) => {
      state.collabTooltip = message;
    },
    setToolsToEnableForCachedSeries: (state, { toolName }) => {
      state.toolsToEnableForCachedSeries.push(toolName);
    }
  }
};
