<template>
  <div class="py-2 text-center">
    <component :is="website ? 'a' : 'span'" :href="website" target="_blank">
      <v-img
        class="mx-auto"
        contain
        :max-height="$vuetify.application.top - 25"
        max-width="80%"
        :src="logo"
      />
    </component>
  </div>
</template>

<script>
export default {
  name: "CustomerLogo",
  data() {
    return {
      logo: process.env.APP_CUSTOMER.bottomLeftLogo,
      website: process.env.APP_CUSTOMER.bottomLeftLogoHref
    };
  }
};
</script>
