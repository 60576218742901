import {
  dashboardGroups,
  dashboardTableHeadersPatients,
  dashboardTableHeadersStudies
} from "@/js/preferences";

const initialFilter = {
  patients: {
    patientName: "",
    patientId: "",
    birthDate: "",
    gender: "",
    customTag: ""
  },
  studies: {
    accessionNumber: "",
    studyDate: "",
    modality: "",
    patientName: "",
    customTag: ""
  }
};
const getInitialState = () => ({
  groupIndex: 0,
  search: "",
  searchFilters: initialFilter,
  selection: [],
  uploadingData: [],
  headers: {
    patients: dashboardTableHeadersPatients,
    studies: dashboardTableHeadersStudies
  },
  isRefreshing: false
});

export default {
  namespaced: true,
  state: getInitialState(),
  getters: {
    group: state => dashboardGroups[state.groupIndex],
    groups: () => dashboardGroups,
    searchFilters: state => state.searchFilters,
    numberOfSelectedSeries: state =>
      state.selection.reduce((sum, d) => sum + d.series.length, 0),
    isUploading: state => {
      if (
        state.uploadingData &&
        state.uploadingData.length &&
        state.uploadingData.length > 0
      ) {
        return true;
      } else {
        return false;
      }
    },
    isRefreshing: state => state.isRefreshing,
    uploadingData: state => state.uploadingData
  },

  mutations: {
    reset: state => {
      const s = getInitialState();
      Object.keys(s).forEach(key => (state[key] = s[key]));
    },
    resetAllFilters: state => (state.searchFilters = initialFilter),
    updateGroup: (state, index) => (state.groupIndex = index),
    updateSearch: (state, value) => (state.search = value),
    updateSelection: (state, value) => (state.selection = value),
    upateUploadingData: (state, value) => {
      state.uploadingData = [...state.uploadingData, ...value];
    },
    updatePatientHeaders: (state, value) => (state.headers.patients = value),
    updateStudiesHeaders: (state, value) => (state.headers.studies = value),
    resetUploading: state => {
      state.uploadingData.splice(0);
    },
    setFilter: (state, { key, value }) => {
      state.searchFilters[key[0]][key[1]] = value;
    },
    resetFilter: (state, key) => {
      state.searchFilters[key[0]][key[1]] = "";
    },
    terminateUploading: (state, value) => {
      if (value) {
        if (
          state.uploadingData &&
          state.uploadingData.length &&
          state.uploadingData.length > 0
        ) {
          let uploadingData = state.uploadingData.filter(
            i => i.larvitarSeriesInstanceUID !== value
          );
          state.uploadingData = uploadingData;
        }
      }
    },
    setRefreshing: (state, isRefreshing) => (state.isRefreshing = isRefreshing)
  }
};
