<template>
  <ditto-dicom-import-modal
    :default-active="isModalActive"
    :disabled="isDisabled"
    badge-color="black"
    icon="mdi-plus"
    icon-color="accent"
    :dark="darkMode"
    :options="importOptions"
    @open-viewer-uploaded="
      uploadedSeries => $root.$emit('open-viewer-uploaded', uploadedSeries)
    "
    @dicom-import-open="openViewer"
    @dicom-import-upload="upload"
  >
    <template v-slot="{ minimizedSeries, on }">
      <v-list-item :disabled="isDisabled" v-on="on" id="IM1">
        <v-list-item-title @click="$emit('close-import-exams-menu')">
          <v-icon class="mr-2">mdi-folder-multiple-plus</v-icon>
          <span>Local files</span>
          <v-progress-circular
            v-if="minimizedSeries"
            class="ml-2"
            color="accent"
            :indeterminate="isUploading"
            :size="24"
            :value="100"
            :width="3"
          >
            {{ minimizedSeries }}
          </v-progress-circular>
        </v-list-item-title>
      </v-list-item>
    </template>

    <template v-slot:size="{ item }">
      [{{ item.x00280010 }}, {{ item.x00280011 }}]
    </template>
  </ditto-dicom-import-modal>
</template>

<script>
import { mapGetters } from "vuex";
import { statusSource as wsStatus } from "@/js/api.series.upload";
import { setup3rdPartyStudy } from "@/js/api.3rdparty";
import { stacksToSchemaFormat, uploadStudies } from "@/js/utils.dicom";
import {
  dicomImportTableHeaders,
  viewerLayouts,
  schemaToStackMap
} from "@/js/preferences";

const defaultUploadStatus = Object.freeze({
  completed: false,
  errors: {},
  loading: false,
  progress: {}
});

const metadata = [
  ...Object.values(schemaToStackMap.patient),
  ...Object.values(schemaToStackMap.study),
  ...Object.values(schemaToStackMap.series)
];

export default {
  name: "ImportExams",
  data() {
    return {
      importOptions: {
        allowAnonymization: true,
        headers: dicomImportTableHeaders,
        inputModes: { filesystem: true, pacs: true },
        metadata: [...new Set(metadata)], // uniq metadata
        steps: [] // configured in mounted function
      },
      uploadStatus: { ...defaultUploadStatus },
      hasUploaded: false,
      // isUploading: false
      is3rdPartyUrl: process.env.APP_CUSTOMER.getExternal3rdPartyURL,
      validCustomerReferrer: true
    };
  },
  mounted() {
    this.importOptions.steps = [
      // step 1
      undefined,
      // step 2
      { actions: [{ disabled: !this.isAuth }] },
      // step 3
      {
        status: { ...defaultUploadStatus }
      }
    ];
    if (this.is3rdPartyUrl) this.checkExternalCustomer();
  },
  computed: {
    ...mapGetters("auth", ["isAuth"]),
    ...mapGetters("dashboard", ["isUploading"]),
    ...mapGetters(["darkMode"]),
    isUploading() {
      if (
        this.$store.state.dashboard.uploadingData &&
        this.$store.state.dashboard.uploadingData.length &&
        this.$store.state.dashboard.uploadingData.length > 0
      ) {
        return true;
      } else {
        return false;
      }
    },
    isSharedLink() {
      return !!this.$route.params.sharedId;
    },
    isDisabled() {
      return (
        this.$isDemoUser ||
        this.isSharedLink ||
        (this.is3rdPartyUrl && !this.validCustomerReferrer)
      );
    },
    isModalActive() {
      return (
        this.is3rdPartyUrl &&
        this.validCustomerReferrer &&
        !this.$route.params.externalStudyId
      );
    }
  },
  methods: {
    openViewer(data) {
      if (this.$isDemoUser) {
        return;
      }

      this.$store.commit("viewer/setServer", this.hasUploaded);
      const schemaData = stacksToSchemaFormat(data);

      schemaData.forEach(study => {
        study.series.forEach(s => {
          // data is uploading/has been uploaded (hybrid mode): set as already downloaded
          s.download_completed = this.hasUploaded;

          // track also uploading status to activate/deactivate 3D tools
          // (needed if upload ends before opening series in viewer)
          const p = this.uploadStatus.progress[s.id];
          s.upload_completed = p && p[0] === p[1];
        });
      });

      if (this.$route.name !== "viewer") {
        this.$store.commit("viewer/initData", schemaData);
        this.$router.push({ name: "viewer" });
      } else {
        // already in viewer
        this.$store.commit("viewer/initData", schemaData);
        this.$store.commit("viewer/updateLayout", viewerLayouts.stack[0]);
        // reset upload flag
        this.hasUploaded = false;
      }
    },
    updateSeriesProgress(id, value) {
      const progress = this.uploadStatus.progress[id];
      this.$set(progress, 0, value);
    },
    updateUploadStatus(key, value) {
      this.uploadStatus[key] = value;
      this.importOptions.steps[2].status[key] = value;
      if (key === "completed" && value === true) {
        // this.$root.$emit("dashboard-refresh");
        //this.importOptions.steps[2].actions[0].disabled = false;
      }
    },
    upload(data) {
      if (this.$isDemoUser) {
        return;
      }

      // Check ws status
      const status = wsStatus.value;
      if (status !== WebSocket.OPEN) {
        this.updateUploadStatus("errors", {
          generic: `ERROR: cannot connect to WebSocket (status=${status})`
        });
        this.updateUploadStatus("completed", true);
        return;
      }

      // Init progress
      this.updateUploadStatus("completed", false);
      this.updateUploadStatus("errors", {});
      this.updateUploadStatus("loading", true);
      this.updateUploadStatus("progress", {});

      this.$store.commit("viewer/setServer", true);
      this.hasUploaded = true;
      // this.isUploading = true;
      this.$store.commit("dashboard/upateUploadingData", data);
      const updateProgress = obj => {
        if (obj.error) {
          let errorStrings = [];

          // default error string (for HttpErrorResponse error type)
          if (obj.error.status) {
            errorStrings = [obj.error.statusText];
          }
          // custom error string (specific study/series errors)
          else if (typeof obj.error == "object") {
            errorStrings = Object.entries(obj.error).map(e => {
              // avoid recursive prompting
              if (typeof e[1] !== "object") {
                if (!Array.isArray(e[1])) {
                  return `${e[0]}: ${e[1]}`;
                }
              }
            });
          } else {
            errorStrings = [obj.error];
          }

          const errors = this.uploadStatus.errors;
          if (obj.seriesId) {
            errors[obj.seriesId] = errors[obj.seriesId] || [];
            errors[obj.seriesId] = [...errors[obj.seriesId], ...errorStrings];
          } else if (obj.error && typeof obj.error.series_index === "number") {
            errors[obj.error.series_index] =
              errors[obj.error.series_index] || [];
            errors[obj.error.series_index] = [
              ...errors[obj.error.series_index],
              ...errorStrings
            ];
          }

          this.updateUploadStatus("errors", errors);
          // this.isUploading = false;
          this.$store.commit("dashboard/resetUploading");
        } else if (obj.start) {
          this.$set(this.uploadStatus.progress, obj.seriesId, [
            0,
            obj.numberOfInstances
          ]);
        } else if (obj.end) {
          // !!! need to update VIEWER series upload info
          // (if upload ends when series is already opened in viewer)
          this.$store.commit("viewer/uploadEnded", obj.seriesId);
          // this.uploading = false;
          this.$store.commit("dashboard/terminateUploading", obj.seriesId);
        } else {
          this.updateSeriesProgress(
            obj.seriesId,
            this.uploadStatus.progress[obj.seriesId][0] + 1
          );
        }
      };

      const sub = uploadStudies(data, null)
        .subscribe({
          next: response => {
            if (response.startUpload) {
              // this.$root.$emit("dashboard-refresh");
            } else if (response.uploadedStudy) {
              this.$root.$emit("dashboard-refresh");
            } else {
              updateProgress(response);
            }
          },
          error: error => updateProgress(error)
        })
        .add(() => {
          console.warn("UPLOAD COMPLETES");
          this.updateUploadStatus("completed", true);
          this.updateUploadStatus("loading", false);
          this.$store.commit("dashboard/resetUploading");
          // this.$root.$emit("dashboard-refresh");
          // this.isUploading = false;
          sub?.unsubscribe();
        });
    },
    checkExternalCustomer() {
      // TODO: generalize url
      const url =
        window.location.hostname == "arzamed-dev.dicom.vision"
          ? "https://dev.arzamed.com"
          : "https://app.arzamed.com";
      this.validCustomerReferrer =
        window.location.hostname == "localhost" ||
        window.document.referrer.startsWith(url);

      if (!this.validCustomerReferrer) {
        this.$store.commit("raiseSnackbar", {
          text: "Please open the viewer from Arzamed App",
          color: "error",
          timeout: -1
        });
        return;
      }

      let studyId = this.$route.params.externalStudyId;
      if (studyId) setup3rdPartyStudy(studyId, this);
    }
  }
};
</script>

<style>
td.cell-size {
  min-width: 90px;
}
</style>
